<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Eliminar Usuario</template>
    <template #body>
      <form @submit.prevent="goToDeleteMe">
        <div class="card-body">
          <FormText
            label="Contraseña"
            password
            v-model="form.password"
          />
          <FormText
            label="Repetir Contraseña"
            password
            v-model="form.password_confirmation"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="goToDeleteMe">
        Eliminar Cuenta
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
     form: {
      password: null,
      password_confirmation: null,
    },
  }),
  mounted() {
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
     goToDeleteMe() {
      const data = {
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      };
      this.$store.dispatch("deleteMyUser",data)
      .then(() => {
       this.close();
      })
    },
  },
};
</script>
<style></style>
