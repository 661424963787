<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title><i class="fa-solid fa-unlock-keyhole"></i> Cambiar Contraseña</template>
      <template #body>
        <form @submit.prevent="savePassword">
          <FormText
            label="Nueva Contraseña"
            password
            v-model="form.new_password"
          />
          <FormText
            label="Repetir Nueva Contraseña"
            password
            v-model="form.new_password_confirmation"
          />
          <FormText
            label="Contraseña Actual"
            password
            v-model="form.password"
          />
        </form>
      </template>
      <template #footer>
        <button class="btn btn-primary" type="submit" @click="savePassword">Actualizar</button>
      </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
export default {
  components: {
    FormText,
  },
  props: {

  },
  data: () => ({
     form: {
      new_password: null,
      new_password_confirmation: null,
      password: null,
    },
  }),
  mounted() {
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    savePassword() {
    this.$store
        .dispatch("updatePassowrdUser", this.form).then(() => {
        this.close();
      });
  },
  },
};
</script>
<style></style>
