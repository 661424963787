<template>
  <div class="card d-flex align-items-center justify-content-between">
    <small><button @click="editDatosPersonales" class="btn btn-info m-2 ml-5" >Editar Datos de Contacto <i class="fa-solid fa-pen"></i></button></small>
    <small><button @click="changePassword" class="btn btn-warning m-2" >Cambiar Contraseña <i class="fa-solid fa-unlock"></i></button></small>
    <small><button @click="deleteUser" class="btn btn-danger m-2 mr-5" >Eliminar MI CUENTA <i class="fa-solid fa-trash"></i></button></small>
  </div> 
  <div class="d-flex align-items-center justify-content-center flex-direction-column">
    <div class="col-md-6">
      <div class="card mt-3">
        <div class="card-header">
          <h4 class="text-primary"><i class="fa-solid fa-user"></i> Mi Cuenta</h4>
        </div>
        <div class="card-body">
          <FormText
            class="text-primary"
            label="Nombre"
            icon="fa-solid fa-user"
            v-model="item.first_name"
            disabled
          />
          <FormText
            class="text-primary"
            label="Apellido"
            icon="fa-solid fa-user"
            v-model="item.last_name"
            disabled
          />
          <FormText
            class="text-primary"
            label="Email"
            icon="fa-solid fa-envelope"
            v-model="item.email"
            disabled
          />
          <FormText
            class="text-primary"
            label="Rol"
            icon="fa-solid fa-chess"
            v-model="item.role_name"
            disabled
          />
        </div>
      </div>
    </div>
      <div class="col-md-6">
      <div class="card mt-3">
          <div class="card-header">
            <h4 class="text-primary"><i class="fa-solid fa-house-chimney-user"></i> Datos de Contacto</h4>
          </div>
          <div class="card-body">
            <FormText
              class="text-primary"
              label="Telefono"
              icon="fa-solid fa-mobile"
              v-model="datosPersonales.telefono"
              disabled
            />
            <FormText
              class="text-primary"
              label="Fax"
              icon="fa-solid fa-phone"
              v-model="datosPersonales.fax"
              disabled
            />
          </div>
          <div class="card-body">
            <FormText
              class="text-primary"
              label="Direccion"
              icon="fa-solid fa-house"
              v-model="datosPersonales.domicilio"
              disabled
            />
            <FormText
              class="text-primary"
              label="Codigo Postal"
              icon="fa-solid fa-hashtag"
              v-model="datosPersonales.cod_postal"
              disabled
            />
          </div>
          <div class="card-body">
            <FormTextString
              class="text-primary"
              label="Pais"
              icon="fa-solid fa-earth-americas"
              :value="pais"
              disabled
            />
            <FormTextString
              class="text-primary"
              label="Provincia"
              icon="fa-solid fa-city"
              :value="provincias"
              disabled
            />
            <FormText
              class="text-primary"
              label="Localidad || C.A.B.A"
              icon="fa-solid fa-landmark"
              v-model="datosPersonales.localidad"
              disabled
            />
          </div>
      </div>
    </div>
  </div>
  <FormDeletedUserModal
      v-if="showModalDeletedUser"
      @close="closeModal"
      
    />
    <FormChangePassword
      v-if="showModalchangePassword"
      @close="closeModal"
    />
    <FormChangeDatosPersonales
      v-if="showModalDatosPersonales"
      @close="closeModal"
      :item="selContact"
    />
</template>

<script>
import FormText from "@/components/Form/FormText.vue";
import FormTextString from "@/components/Form/FormTextString.vue";
import FormDeletedUserModal from "./FormDeletedUserModal.vue";
import FormChangePassword from "./FormChangePassword.vue";
import FormChangeDatosPersonales from "./FormChangeDatosPersonales.vue"

export default {
  components: {
    FormText,
    FormTextString,
    FormDeletedUserModal,
    FormChangePassword,
    FormChangeDatosPersonales,
  },
  data: () => ({
    showModalDeletedUser: false,
    showModalchangePassword: false,
    showModalDatosPersonales: false,
    selItem: null,
    selContact: null,
  }),
  computed: {
    pais(){
      if(this.$store.getters.datos_personales.pais === 'AR') {return 'Argentina'}
      return this.$store.getters.datos_personales.pais
    },
    provincias(){
      const provincias = [{value: 'BA', name: 'Buenos Aires'},{value: 'CA', name: 'Catamarca'},{value: 'CH', name: 'Chaco'},{value: 'CT', name: 'Chubut'},{value: 'CB', name: 'Córdoba'},{value: 'CR', name: 'Corrientes'},{value: 'ER', name: 'Entre Ríos'},{value: 'FO', name: 'Formosa'},{value: 'JY', name: 'Jujuy'},{value: 'LP', name: 'La Pampa'},{value: 'LR', name: 'La Rioja'},{value: 'MZ', name: 'Mendoza'},{value: 'MI', name: 'Misiones'},{value: 'NQN', name: 'Neuquén'},{value: 'RN', name: 'Río Negro'},{value: 'SA', name: 'Salta'},{value: 'SJ', name: 'San Juan'},{value: 'SL', name: 'San Luis'},{value: 'SC', name: 'Santa Cruz'},{value: 'SF', name: 'Santa Fe'},{value: 'SE', name: 'Santiago del Estero'},{value: 'TF', name: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur'},{value: 'TU', name: 'Tucumán'}]
      const resultado = provincias.find(x=>x.value === this.$store.getters.datos_personales.provincia)
      if(!resultado){return this.$store.getters.datos_personales.provincia}
      return resultado.name
    },
    item() {
      return this.$store.getters.user
    },
    datosPersonales() {
      return this.$store.getters.datos_personales
    }
  },
  async mounted() {
    await this.getDatosPersonales();
  },
  methods: {
    getDatosPersonales(){
      this.$store.dispatch('geteDatosPersonales');
    },
    closeModal() {
      this.selItem = null;
      this.selContact = null;
      this.showModalDeletedUser = false;
      this.showModalchangePassword = false;
      this.showModalDatosPersonales = false;
    },
    changePassword(){
      this.showModalchangePassword = true;
    },
    editDatosPersonales(){
      this.showModalDatosPersonales = true;
      this.selContact = this.datosPersonales;
    },
    deleteUser() {
      this.$alert
        .fireConfirm("Eliminar Mi Cuenta", "Esta seguro que desea continuar?")
        .then(() => {
          this.showModalDeletedUser = true;
        });
    },
  },
};
</script>

<style>
@media (max-width: 782px) {
  .flex-direction-column{
  flex-direction:column;
}
}

</style>
