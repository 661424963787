<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title><i class="fa-solid fa-house-chimney-user"></i> Editar Datos Personales / Contacto</template>
      <template #body>
        <form @submit.prevent="saveDatosPersonales">
          <FormText
            class="text-primary"
            label="Telefono"
            icon="fa-solid fa-mobile"
            v-model="form.telefono"
          />
          <FormText
            class="text-primary"
            label="Fax"
            icon="fa-solid fa-phone"
            v-model="form.fax"
          />
          <FormText
            class="text-primary"
            label="Domicilio"
            icon="fa-solid fa-house"
            v-model="form.domicilio"
          />
          <FormText
            class="text-primary"
            label="Codigo Postal"
            icon="fa-solid fa-hashtag"
            v-model="form.cod_postal"
          />
          <template v-if="form.pais === 'AR'">
            <FormSelect
              class="text-primary"
              label="Pais"
              :options="paises"
              icon="fa-solid fa-earth-americas"
              v-model="form.pais"
            />
          </template>
          <template v-else>
            <FormText
              class="text-primary"
              label="Pais"
              :placeholderText="placeholder"
              icon="fa-solid fa-earth-americas"
              v-model="form.pais"
            />
          </template>  
          <template v-if="form.pais === 'AR'">
            <FormSelect
              class="text-primary"
              label="Provincia"
              icon="fa-solid fa-city"
              :options="provincias"
              v-model="form.provincia"
            />
          </template>
          <template v-else-if="form.pais?.toUpperCase() === 'ARGENTINA'">
            <FormSelect
              class="text-primary"
              label="Provincia"
              icon="fa-solid fa-city"
              :options="provincias"
              v-model="form.provincia"
            />
          </template>
          <template v-else-if="form.pais === 'Argentina'">
            <FormSelect
              class="text-primary"
              label="Provincia"
              icon="fa-solid fa-city"
              :options="provincias"
              v-model="form.provincia"
            />
          </template>
          <template v-else>
            <FormText
              class="text-primary"
              label="Provincia"
              icon="fa-solid fa-city"
              v-model="form.provincia"
            />
          </template>  
            <FormText
              class="text-primary"
              label="Localidad || C.A.B.A"
              icon="fa-solid fa-landmark"
              v-model="form.localidad"
            />
        </form>
      </template>
      <template #footer>
        <button class="btn btn-primary" type="submit" @click="saveDatosPersonales">Actualizar</button>
      </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
export default {
  components: {
    FormText,
    FormSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed:{
    paises(){
      return [{value: 'AR', name: 'Argentina'}, {value: '', name: 'Otro'}]
    },
    provincias(){
      return [{value: 'BA', name: 'Buenos Aires'},{value: 'CA', name: 'Catamarca'},{value: 'CH', name: 'Chaco'},{value: 'CT', name: 'Chubut'},{value: 'CB', name: 'Córdoba'},{value: 'CR', name: 'Corrientes'},{value: 'ER', name: 'Entre Ríos'},{value: 'FO', name: 'Formosa'},{value: 'JY', name: 'Jujuy'},{value: 'LP', name: 'La Pampa'},{value: 'LR', name: 'La Rioja'},{value: 'MZ', name: 'Mendoza'},{value: 'MI', name: 'Misiones'},{value: 'NQN', name: 'Neuquén'},{value: 'RN', name: 'Río Negro'},{value: 'SA', name: 'Salta'},{value: 'SJ', name: 'San Juan'},{value: 'SL', name: 'San Luis'},{value: 'SC', name: 'Santa Cruz'},{value: 'SF', name: 'Santa Fe'},{value: 'SE', name: 'Santiago del Estero'},{value: 'TF', name: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur'},{value: 'TU', name: 'Tucumán'}]
    }
  },
  data: () => ({
     form: {
      telefono: null,
      fax: null,
      domicilio: null,
      cod_postal: null,
      localidad: null,
      provincia: null,
      pais: null,
    },
    placeholder: 'Escriba su pais',
  }),
  mounted() {
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveDatosPersonales() {
    if (this.form.pais?.toUpperCase() === "ARGENTINA") {return this.form.pais = "AR"}
    this.$store
        .dispatch("updateDatosPersonales", this.form).then(() => {
        this.close();
      });
  },
  },
};
</script>
<style></style>
